<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Faktur Pajak Keluaran</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Pajak / Faktur Pajak Keluaran </span>
      </div>
    </div>

    <div class="card bg-white">
      <form @submit.prevent="onExport(!v$.$invalid)">
        <div class="card bg-white">
          <div class="col-12 md:col-12">
            <div class="formgrid grid">
              <div class="field col-12">
                <div>Periode</div>
              </div>
              <div class="field col-12 md:col-2">
                <Calendar
                  v-model="v$.form.date_from.$model"
                  dateFormat="dd/mm/yy"
                  :class="{
                    'p-invalid': v$.form.date_from.$invalid && submitted,
                  }"
                  placeholder="Tanggal (dari)"
                  class="w-full"
                />
                <small
                  v-if="
                    (v$.form.date_from.$invalid && submitted) ||
                    v$.form.date_from.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.date_from.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-2">
                <Calendar
                  v-model="v$.form.date_to.$model"
                  dateFormat="dd/mm/yy"
                  :class="{
                    'p-invalid': v$.form.date_to.$invalid && submitted,
                  }"
                  placeholder="Tanggal (s/d)"
                  class="w-full"
                />
                <small
                  v-if="
                    (v$.form.date_to.$invalid && submitted) ||
                    v$.form.date_to.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.date_to.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-3">
                <Button
                  :loading="isLoadingExport"
                  type="submit"
                  icon="pi pi-file-excel"
                  label="Export"
                  class="ml-2"
                />
                <Button
                  type="reset"
                  icon="pi pi-refresh"
                  label="Reset"
                  class="p-button-outlined ml-2"
                  @click="resetForm"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import errorHandler from '@/helpers/error-handler'
import PajakSeriService from '@/services/PajakSeriService'

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {},
  data() {
    return {
      dataService: null,
      isLoading: false,
      isLoadingExport: false,
      items: [],
      invoices: [],
      submitted: false,
      form: {
        id: 0,
        date_from: null,
        date_to: null,
      },
    }
  },
  created() {
    this.dataService = new PajakSeriService()
  },
  mounted() {
    this.onLoadGridData()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.onLoadGridData()
    },
    onLoadGridData() {},
    onExport(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }
      const startDate = dayjs(this.form.date_from).format('YYYY-MM-DD')
      const endDate = dayjs(this.form.date_to).format('YYYY-MM-DD')
      this.isLoadingExport = true
      this.dataService
        .exportCsv(startDate, endDate)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'faktur-pajak-keluaran.xlsx')
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          errorHandler(err, 'Export Data Faktur Pajak Keluaran', this)
        })
        .finally(() => (this.isLoadingExport = false))
    },
    resetForm() {
      this.submitted = false
      this.form.date_to = null
      this.form.date_from = null
    },
  },
  validations() {
    return {
      form: {
        date_from: {
          required: helpers.withMessage(
            'Tanggal (dari) harus diisi.',
            required
          ),
        },
        date_to: {
          required: helpers.withMessage('Tanggal (s/d) harus diisi.', required),
        },
      },
    }
  },
}
</script>
