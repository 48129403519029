<template>
  <div class="card">
    <DataTable :value="items" class="p-datatable-sm" showGridlines scrollable>
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column field="si_no" header="No Faktur" style="min-width: 11rem" />
      <Column field="si_at" header="Tgl Faktur" style="min-width: 5rem">
        <template #body="{ data }">
          {{ formatDate(data.si_at) }}
        </template>
      </Column>
      <Column field="nama_obat" header="Obat" style="min-width: 13rem" />
      <Column
        field="quantity"
        header="QTY"
        style="min-width: 6.1rem; flex-grow: 1; justify-content: flex-end"
      />
      <Column field="satuan" header="Satuan" style="min-width: 5rem" />
      <Column
        field="harga"
        header="Harga"
        style="min-width: 7rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.harga) }}
        </template>
      </Column>
      <Column
        field="diskon"
        header="Diskon (%)"
        style="min-width: 7rem; flex-grow: 1; justify-content: flex-end"
      />
      <Column
        field="diskon_harga"
        header="Diskon (Rp)"
        style="min-width: 7rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency((data.harga * data.diskon) / 100) }}
        </template>
      </Column>
      <Column
        field="dpp"
        header="DPP"
        style="min-width: 9rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.dpp) }}
        </template>
      </Column>
      <Column
        field="ppn"
        header="PPN"
        style="min-width: 6.1rem; flex-grow: 1; justify-content: flex-end"
      />
      <!--
      <Column
        field="pph"
        header="PPH"
        style="min-width: 6.1rem; flex-grow: 1; justify-content: flex-end"
      />
      -->
      <Column
        field="harga_total"
        header="Jumlah"
        style="min-width: 9rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.harga * data.quantity) }}
        </template>
      </Column>
      <Column field="batch_no" header="No Batch" style="min-width: 6rem" />
      <Column field="expired_at" header="Tgl. Expire" style="min-width: 5rem">
        <template #body="{ data }">
          {{ formatDate(data.expired_at) }}
        </template>
      </Column>
      <Column field="keterangan" header="keterangan" style="min-width: 13rem" />
      <Column v-if="showButton" style="flex-grow: 1; justify-content: flex-end">
        <template #body="{ data }">
          <Button v-if="status == 'draft'"
            type="button"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-secondary p-button-text mr-2"
            @click="$emit('edit', data)"
          />
          <Button v-else
            type="button"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-secondary p-button-text mr-2"
            @click="$emit('edit', data)"
            disabled
          />
          <Button v-if="status == 'draft'"
            type="button"
            icon="pi pi-trash"
            class="p-button-outlined p-button-danger p-button-text"
            @click="$emit('delete', data)"
          />
          <Button v-else
            type="button"
            icon="pi pi-trash"
            class="p-button-outlined p-button-danger p-button-text"
            @click="$emit('delete', data)"
            disabled
          />
        </template>
      </Column>
      <template #footer>
        <table width="340px" class="flex justify-content-end">
          <tr>
            <td width="100%">
              <table class="justify-content-end">
                <tr>
                  <td>SUB TOTAL</td>
                  <td style="width: 150px" class="text-right">
                    {{ formatCurrency(subTotal.subTotal) }}
                  </td>
                </tr>
                <tr>
                  <td>DISKON</td>
                  <td class="text-right">
                    {{ formatCurrency(subTotal.totalDiskon) }}
                  </td>
                </tr>
                <tr>
                  <td>SUB JUMLAH</td>
                  <td class="text-right">
                    {{ formatCurrency(subTotal.totalDPP, 2, 2) }}
                  </td>
                </tr>
                <tr>
                  <td>DPP</td>
                  <td class="text-right">
                    {{ formatCurrency(subTotal.totalNewDPP, 2, 2) }}
                  </td>
                </tr>
                <tr>
                  <td>PPN</td>
                  <td class="text-right">
                    {{ formatCurrency(subTotal.totalPPN, 2, 2) }}
                  </td>
                </tr>
                <!--
                <tr>
                  <td>PPH 22&nbsp;</td>
                  <td class="text-right">
                    {{ formatCurrency(subTotal.totalPPH) }}
                  </td>
                </tr>
                -->
                <!--
                <tr>
                  <td>CN</td>
                  <td class="text-right">
                    <InputNumber
                      v-model="credit"
                      :minFractionDigits="0"
                      :min="0"
                      :disabled="!showButton"
                      mode="currency"
                      currency="IDR"
                      locale="id-ID"
                      @input="updateCN"
                    />
                  </td>
                </tr>
                <tr>
                  <td>DN</td>
                  <td class="text-right">
                    <InputNumber
                      v-model="debit"
                      :minFractionDigits="0"
                      :min="0"
                      :disabled="!showButton"
                      mode="currency"
                      currency="IDR"
                      locale="id-ID"
                      @input="updateDN"
                    />
                  </td>
                </tr>
                -->
                <tr>
                  <td>JUMLAH</td>
                  <td class="text-right">
                    {{ formatCurrency(subTotal.total, 2, 2) }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { formatCurrency, formatDate } from '@/helpers'
import SettingService from '@/services/SettingService'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    /*
    cn: {
      type: Number,
      default: 0,
    },
    dn: {
      type: Number,
      default: 0,
    },
    */
   status: {
    type: String,
    default: ''
   },
  },
  data() {
    return {
      ppn: 0,
      /*
      debit: this.dn,
      credit: this.cn,
      */
    }
  },
  mounted() {
    const settingService = new SettingService()
    settingService.get('?filter[key]=PPN').then((res) => {
      this.ppn = res.data.data.value
    })
  },
  /*
  watch: {
    dn(e) {
      this.debit = e
    },
    cn(e) {
      this.credit = e
    },
  },
  */
  computed: {
    subTotal() {
      let res = {}
      const self = this

      const subTotalNett = this.items.reduce(function (total, item) {
        const sub_jumlah = item.harga * item.quantity
        return total + sub_jumlah
      }, 0)

      const totalDPP = this.items.reduce(function (total, item) {
        return total + item.dpp
      }, 0)

      const totalDiskon = subTotalNett - totalDPP

      const totalPPN = this.items.reduce(function (total, item) {
        const subJumlah = item.harga * item.quantity
        const diskon_persentase = item.diskon
          ? ((item.harga * item.diskon) / 100) * item.quantity
          : 0
        const diskon_rp =
          item.diskon_harga > 0 ? item.diskon_harga * item.quantity : 0
        const harga_diskon = item.diskon > 0 ? diskon_persentase : diskon_rp
        
        const ppn = Math.floor((item.ppn / 100) * (subJumlah - harga_diskon))
        return total + ppn
      }, 0)

      const totalPPH = this.items.reduce(function (total, item) {
        const pph = item.pph ? (item.pph / 100) * item.harga * item.quantity : 0

        return total + pph
      }, 0)

      res.subTotal = subTotalNett
      res.totalDiskon = totalDiskon
      res.totalDPP = totalDPP
      res.totalNewDPP = Math.round(((11/12) * totalDPP) * 100) / 100
      res.totalPPN = totalPPN
      res.totalPPH = totalPPH
      res.total =
        res.totalDPP + totalPPN + res.totalPPH // + this.debit - this.credit
      return res
    },
  },
  methods: {
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
    /*
    updateDN(e) {
      const dn = e.value === null ? 0 : e.value
      this.debit = dn
      this.credit = 0
      this.$emit('dn', dn)
      this.$emit('cn', this.credit)
    },
    updateCN(e) {
      const cn = e.value === null ? 0 : e.value
      this.credit = cn
      this.debit = 0
      this.$emit('cn', cn)
      this.$emit('dn', this.debit)
    },
    */
  },
}
</script>
