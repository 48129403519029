<template>
  <div>
    <DataTable
      v-model:filters="filters"
      v-model:expandedRows="expandedRows"
      :value="items"
      :loading="loading"
      :rows="10"
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      filterDisplay="row"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      class="p-datatable-sm"
      row-hover
      paginator
      scrollable
      lazy
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      :rowClass="getRowClass"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column style="min-width: 3rem; flex: initial" header="" expander />
      <Column
        field="pi_no"
        header="No. Bukti"
        style="min-width: 15rem"
        headerStyle="min-width: 15rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        dataType="date"
        field="pi_at"
        filter-field="pi_between"
        header="Tgl. Terima"
        style="min-width: 19rem"
        headerStyle="min-width: 19rem"
        sortable
      >
        <template #body="{ data }">
          {{ formatDate(data.pi_at) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <CalendarFilter
            ref="calendarFilterTglTerima"
            v-model="filterModel.value"
            @select="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="inv_no"
        header="No. Faktur"
        style="min-width: 12rem"
        headerStyle="min-width: 12rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        dataType="date"
        field="inv_at"
        filter-field="inv_between"
        header="Tgl. Faktur"
        style="min-width: 19rem"
        headerStyle="min-width: 19rem"
        sortable
      >
        <template #body="{ data }">
          {{ formatDate(data.inv_at) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <CalendarFilter
            ref="calendarFilter"
            v-model="filterModel.value"
            @select="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="supplier.nama"
        sort-field="supplier"
        filter-field="supplier"
        header="Supplier"
        style="min-width: 19rem"
        headerStyle="min-width: 19rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column field="status" header="Status" style="min-width: 13rem" sortable>
        <template #body="{ data }">
          <div :class="'status-badge status-' + data.status">
            {{ data.status }}
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            :options="['draft', 'open', 'pend', 'done']"
            class="p-column-filter"
            showClear
            @change="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="status_top"
        header="Status Top"
        style="min-width: 15rem"
        sortable
      >
        <template #body="{ data }">
          <div>
            {{ data.status_top }}
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            :options="['telat', 'tidak-telat', 'tenggang']"
            :showClear="true"
            class="p-column-filter"
            @change="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="jumlah"
        header="Total"
        type="numeric"
        style="min-width: 60px; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.jumlah, 2, 2) }}
        </template>
      </Column>
      <Column
        style="
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
          min-width: 180px;
        "
      >
        <template #body="{ data }">
          <Button
            type="button"
            icon="pi pi-print"
            class="p-button-outlined p-button-secondary p-button-text mr-2"
            @click="$emit('printfaktur', data)"
          />
          <riwayat-status :items="data.status_transition" />
          <!-- prettier-ignore-start -->    
          <!-- prettier-ignore-attribute -->
          <Button
            :disabled="data.status === 'done' || auth.user.roles.some((a) => ['pembelian'].includes(a))"
            type="button"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-secondary p-button-text"
            @click="$emit('edit', data)"
          />
          <!-- prettier-ignore-end -->
          <!-- <Button
            :disabled="data.status !== 'draft'"
            type="button"
            icon="pi pi-trash"
            class="p-button-outlined p-button-danger p-button-text"
            @click="$emit('delete', data)"
          /> -->
        </template>
      </Column>
      <template #expansion="slotProps">
        <grid-purchase-invoice-detail
          :items="slotProps.data.obat"
          :diskon="slotProps.data.diskon_tambahan"
          :jenis-harga="slotProps.data.jenis_harga"
          :ppn="slotProps.data.ppn"
          :nilai-pph="slotProps.data.pph"
          :nilai-materai="slotProps.data.materai"
          :show-button="false"
        />
      </template>
    </DataTable>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'
import { formatCurrency, formatDate } from '@/helpers'
import GridPurchaseInvoiceDetail from '@/components/pembelian/GridPurchaseInvoiceDetail'
import CalendarFilter from '@/components/CalendarFilter'
import RiwayatStatus from '@/components/RiwayatStatus'
import { useAuthStore } from '@/store/auth'

export default {
  components: {
    GridPurchaseInvoiceDetail,
    CalendarFilter,
    RiwayatStatus,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      options: this.gridOptions,
      expandedRows: [],
      filters: {
        pi_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
        pi_between: { value: null, matchMode: FilterMatchMode.CONTAINS },
        inv_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
        inv_between: { value: null, matchMode: FilterMatchMode.CONTAINS },
        supplier: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status_top: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      auth: {},
    }
  },
  created() {
    this.auth = useAuthStore()
  },
  watch: {
    'options.filters'(value) {
      if (!value.pi_between.value) {
        this.$refs.calendarFilterTglTerima.clearValueOnly()
      } else if (!value.inv_between.value) {
        this.$refs.calendarFilter.clearValueOnly()
      }
    },
  },
  methods: {
    getRowClass(data) {
      if (data.status_top == 'tenggang') {
        return 'custom-bg-orange'
      } else if (data.status_top == 'telat') {
        return 'custom-bg-pink'
      } else {
        return ''
      }
    },
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.page = 1
      this.options.filters = this.filters
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
    getStatus(id_status) {
      let status = '-'

      const list_jenis_harga = [
        { value: 1, label: 'TERMASUK PAJAK' },
        { value: 2, label: 'BELUM TERMASUK PAJAK' },
        { value: 3, label: 'NON PAJAK' },
      ]

      const res = list_jenis_harga.find((e) => e.value === id_status)
      if (res) {
        status = res.label
      }

      return status
    },
  },
}
</script>
