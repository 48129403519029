<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12">
          <label>Obat</label>
          <InputText
            v-model="form.nama_obat"
            type="text"
            class="w-full"
            disabled
          />
        </div>
        <div class="field col-12 md:col-2">
          <label>Quantity</label>
          <InputNumber
            v-model="form.quantity"
            input-class="w-full"
            class="w-full"
            @input="updateTotal"
            autofocus
          />
        </div>
        <div class="field col-12 md:col-2">
          <label>Satuan</label>
          <InputText
            v-model="form.satuan"
            type="text"
            class="w-full"
            disabled
          />
        </div>
        <div class="field col-12 md:col-3">
          <label>Harga</label>
          <InputNumber
            v-model="form.harga"
            :minFractionDigits="0"
            mode="currency"
            currency="IDR"
            locale="id-ID"
            class="w-full"
            input-class="w-full"
            disabled
          />
        </div>
        <!-- <div class="field col-12 md:col-4">
          <label>Harga (Diskon)</label>
          <InputNumber
            v-model="form.harga_diskon"
            mode="currency"
            currency="IDR"
            locale="id-ID"
            class="w-full"
            input-class="w-full"
            disabled
          />
        </div> -->
        <div class="field col-12 md:col-2">
          <label>Diskon (%)</label>
          <InputNumber
            v-model="form.diskon"
            :minFractionDigits="2"
            locale="id-ID"
            type="text"
            class="w-full"
            input-class="w-full"
            disabled
          />
        </div>
        <div class="field col-12 md:col-3">
          <label>Diskon (Rp)</label>
          <InputNumber
            v-model="form.diskon_harga"
            :minFractionDigits="0"
            class="w-full"
            input-class="w-full"
            disabled
          />
        </div>
        <div class="field col-12 md:col-4">
          <label>Jumlah</label>
          <InputNumber
            v-model="form.jumlah"
            :minFractionDigits="0"
            mode="currency"
            currency="IDR"
            locale="id-ID"
            class="w-full"
            input-class="w-full"
            disabled
          />
        </div>
        <div class="field col-12 md:col-4">
          <label
            :class="{
              'p-error': v$.form.obat_batch.$invalid && submitted,
            }"
            >Batch</label
          >
          <Dropdown
            v-model="form.obat_batch"
            :disabled="form.id > 0"
            :options="item.batch"
            dataKey="id"
            optionLabel="batch_no"
            placeholder="Pilih batch"
            class="w-full"
            input-class="w-full"
            @change="selectTanggal"
            :class="{
              'p-invalid': v$.form.obat_batch.$invalid && submitted,
            }"
          >
            <template #value="slotProps">
              <div class="p-dropdown-car-value" v-if="slotProps.value">
                <span>{{ slotProps.value.batch_no }}</span>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="p-dropdown-car-option">
                <div>
                  <strong>{{ slotProps.option.batch_no }}</strong
                  ><br />
                  Expired: {{ formatDate(slotProps.option.expired_at) }}<br />
                  Stock: {{ slotProps.option.quantity }}<br />
                  Supplier: {{ slotProps.option.nama_supplier }}
                </div>
              </div>
            </template>
          </Dropdown>
          <small
            v-if="
              (v$.form.obat_batch.$invalid && submitted) ||
              v$.form.obat_batch.$pending.$response
            "
            class="p-error"
            >{{ v$.form.obat_batch.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label>Tgl. Expired</label>
          <Calendar
            v-model="form.expired_at"
            dateFormat="dd/mm/yy"
            class="w-full"
            input-class="w-full"
            disabled
          />
        </div>
        <!--
        <div class="field col-12">
          <label>Keterangan</label>
          <InputText
            v-model="form.keterangan"
            class="w-full"
            input-class="w-full"
          />
        </div>
        -->
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { formatDate } from '@/helpers'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    selectTanggal(e) {
      this.form.expired_at = new Date(e.value.expired_at)
      this.form.batch_no = e.value.batch_no
      this.form.batch_id = e.value.batch_id
    },
    formatDate(value) {
      return formatDate(value)
    },
    handleSubmit(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      this.$emit('save', this.form)
    },
    updateTotal(e) {
      this.form.jumlah = Math.round(e.value * this.form.harga)
      this.form.diskon_tambahan = this.form.diskon_tambahan == 0 ? 0 : ((this.form.diskon_tambahan / this.qtyOld) * e.value).toFixed(2)
    },
  },
  validations() {
    return {
      form: {
        obat_batch: {
          required: helpers.withMessage('Batch harus diisi.', required),
        },
      },
    }
  },
}
</script>
