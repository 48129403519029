<template>
  <div class="card">
    <DataTable
      v-model:expandedRows="expandedRows"
      :value="items"
      :scrollable="true"
      class="p-datatable-sm"
      showGridlines
      scrollHeight="400px"
    >
      <Column style="min-width: 3rem; flex: initial" header="" expander />
      <Column field="sp_no" header="SO" style="min-width: 13rem" />
      <Column field="sp_at" header="Tgl SO" style="min-width: 8rem">
        <template #body="{ data }">
          {{ formatDate(data.sp_at) }}
        </template>
      </Column>
      <Column field="golongan" header="Golongan" style="min-width: 8rem" />
      <Column field="nama_obat" header="Obat" style="min-width: 15rem" />
      <Column field="quantity" header="QTY" style="min-width: 3rem" />
      <Column field="satuan" header="Satuan" style="min-width: 5rem" />
      <Column
        field="quantity_tkcl"
        header="QTY terkecil"
        style="min-width: 5rem"
      />
      <Column
        field="satuan_tkcl"
        header="Satuan terkecil"
        style="min-width: 5rem"
      />
      <Column field="harga" header="Harga" style="min-width: 7rem">
        <template #body="{ data }">
          {{ formatCurrency(data.harga) }}
        </template>
      </Column>
      <Column field="diskon" header="Diskon (%)" style="min-width: 5rem" />
      <Column
        field="diskon_rp"
        header="Diskon (Rp)"
        style="flex-grow: 1; min-width: 5rem; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.diskon_rp, 2, 2) }}
        </template>
      </Column>
      <!--<Column
        field="dpp"
        header="Harga dpp"
        style="flex-grow: 1; min-width: 7rem; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.dpp) }}
        </template>
      </Column>-->
      <Column
        field="jumlah"
        header="Harga Total"
        style="flex-grow: 1; min-width: 9rem; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.harga * data.quantity_tkcl) }}
        </template>
      </Column>
      <Column v-if="showButton || showEdit">
        <template #body="{ data }">
          <div class="flex justify-content-end align-items-end">
            <Button
              v-if="showEdit"
              type="button"
              icon="pi pi-pencil"
              class="p-button-outlined p-button-secondary p-button-text mr-2"
              @click="$emit('edit', data)"
            />
            <Button
              v-if="showButton"
              type="button"
              icon="pi pi-trash"
              class="p-button-outlined p-button-danger p-button-text"
              @click="$emit('delete', data)"
            />
          </div>
        </template>
      </Column>
      <template #expansion="slotProps">
        <grid-batch :items="slotProps.data.batch" />
      </template>
      <template #footer>
        <div class="flex justify-content-between flex-wrap">
          <div class="text-primary">{{ total }}/27</div>
          <div>
            <table class="flex justify-content-end" width="300px">
              <td style="width: 130px">
                <tr>
                  SUB TOTAL
                </tr>
                <tr>
                  SUB JUMLAH
                </tr>
                <tr>
                  DPP
                </tr>
                <tr>
                  PPN
                </tr>
                <tr>
                  &nbsp;
                </tr>
                <!--<tr>
                  JUMLAH
                </tr>
                <tr>
                  MATERAI
                </tr>
                <tr>
                  &nbsp;
                </tr>-->
                <tr>
                  TOTAL
                </tr>
              </td>
              <td>
                <tr class="text-right">
                  {{
                    formatCurrency(footer.subTotal)
                  }}
                </tr>
                <tr class="text-right">
                  {{
                    formatCurrency(footer.totalDPP, 2, 2)
                  }}
                </tr>
                <tr class="text-right">
                  {{
                    formatCurrency(footer.totalNewDPP, 2, 2)
                  }}
                </tr>
                <tr class="text-right">
                  {{
                    formatCurrency(footer.totalPPN, 2, 2)
                  }}
                </tr>
                <tr>
                  &nbsp;
                </tr>
                <!--<tr class="text-right">
                  {{
                    formatCurrency(footer.jumlah)
                  }}
                </tr>
                <tr class="text-right">
                  {{
                    formatCurrency(footer.materai)
                  }}
                </tr>
                <tr>
                  &nbsp;
                </tr>-->
                <tr class="text-right">
                  {{
                    formatCurrency(footer.total, 2, 2)
                  }}
                </tr>
              </td>
            </table>
          </div>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { formatCurrency, formatDate, formatNumber } from '@/helpers'
import GridBatch from '@/components/batch/GridBatch'
import SettingService from '@/services/SettingService'

export default {
  components: {
    GridBatch,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    total: {
      type: Number,
      default: 0,
    },
    materai: {
      type: Number,
      default: 0,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expandedRows: [],
      ppn: 0,
    }
  },
  watch: {
    'footer.total'(value) {
      this.$emit('total', value)
    },
  },
  mounted() {
    const settingService = new SettingService()
    settingService.get('?filter[key]=PPN').then((res) => {
      this.ppn = res.data.data.value
    })
  },
  computed: {
    footer() {
      const self = this

      const subTotalNett = this.items.reduce(function (total, item) {
        //const sub_jumlah = item.harga * item.quantity_tkcl
        const sub_jumlah = Math.round(item.harga * item.quantity_tkcl)
        return total + sub_jumlah
      }, 0)

      /*
      const totalDPP = this.items.reduce(function (total, item) {
        const priceAfterDiscount =
          item.diskon === 0
            ? item.harga
            : item.harga * ((100 - item.diskon) / 100)
        return (
          total +
          (Math.round(priceAfterDiscount * 100) / 100) * item.quantity_tkcl
        )
      }, 0)
      */
      /*
      const totalDiskon = this.items.reduce(function (total, item) {
        const diskon = Math.round(
          item.diskon === 0
            ? 0
            : (item.diskon * item.harga) / 100
        )
        return total + Math.round(diskon * item.quantity_tkcl)
      }, 0)
      */
      const totalDPP = this.items.reduce(function (total, item) {
        /*
        const dpp = (
          item.diskon === 0
            ? item.harga
            : item.harga - (item.harga * item.diskon / 100)
        )
        return total + (dpp * item.quantity_tkcl)
        */
        return total + Math.round((item.harga_diskon * item.quantity_tkcl) * 100) / 100
      }, 0)
      //const totalDPP = Math.round(subTotalNett - totalDiskon)

      const totalNewDPP = Math.round(((11/12) * totalDPP) * 100) / 100

      const totalPPN = this.items.reduce(function (total, item) {
        /*
        const dpp = (
          item.diskon === 0
            ? item.harga
            : item.harga - (item.harga * item.diskon / 100)
        )
        */
        const dpp = item.harga_diskon
        return (
          total +
          Math.floor((Math.round((dpp * item.quantity_tkcl) * 100) / 100) * (self.ppn / 100))
        )
      }, 0)
      const totalAfterPPN = totalDPP + totalPPN
      /*
      const totalAfterPPN = this.items.reduce(function (total, item) {
        return total + item.harga_total
      }, 0)
      */
      let res = {}
      res.totalPPN = totalPPN
      res.subTotal = subTotalNett
      res.totalDPP = totalDPP
      res.totalNewDPP = totalNewDPP
      res.jumlah = totalAfterPPN
      res.materai = 0 //this.materai
      res.total = totalAfterPPN // + parseInt(this.materai)
      return res
    },
  },
  methods: {
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatNumber(value) {
      return formatNumber(value)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
