<template>
  <div>
    <DataTable
      v-model:filters="filters"
      v-model:expandedRows="expandedRows"
      :value="items"
      :loading="loading"
      :rows="10"
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      filterDisplay="row"
      dataKey="id"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      class="p-datatable-sm"
      row-hover
      paginator
      scrollable
      lazy
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column style="min-width: 3rem; flex: initial" header="" expander />
      <Column
        field="sr_no"
        header="No. Retur"
        style="min-width: 15rem"
        headerStyle="min-width: 15rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        dataType="date"
        field="sr_at"
        filter-field="sr_between"
        header="Tgl. Retur"
        style="min-width: 19rem"
        headerStyle="min-width: 19rem"
        sortable
      >
        <template #body="{ data }">
          {{ formatDate(data.sr_at) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <CalendarFilter
            ref="calendarFilter"
            v-model="filterModel.value"
            @select="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="pelanggan.nama"
        sort-field="pelanggan"
        filter-field="pelanggan"
        header="Pelanggan"
        style="min-width: 19rem"
        headerStyle="min-width: 19rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column sortable field="status" header="Status" style="min-width: 13rem">
        <template #body="{ data }">
          <div :class="'status-badge status-' + data.status">
            {{ data.status }}
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            :options="['draft', 'sent']"
            class="p-column-filter"
            @change="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="jumlah"
        header="Total"
        type="numeric"
        style="min-width: 70px; max-width: 200px; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.jumlah, 2, 2) }}
        </template>
      </Column>
      <Column
        style="
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
        "
        class="column-action__33"
      >
        <template #body="{ data }">
          <Button
            type="button"
            icon="pi pi-print"
            class="p-button-outlined p-button-secondary p-button-text mr-2"
            @click="$emit('print', data)"
          />
          <Button
            type="button"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-secondary p-button-text"
            @click="$emit('edit', data)"
          />
          <Button v-if="data.status === 'draft'"
            type="button"
            icon="pi pi-trash"
            class="p-button-outlined p-button-danger p-button-text"
            @click="$emit('delete', data)"
          />
        </template>
      </Column>
      <template #expansion="slotProps">
        <grid-retur-penjualan-detail
          :items="slotProps.data.obat"
          :cn="slotProps.data.cn"
          :dn="slotProps.data.dn"
          :status="slotProps.data.status"
        />
      </template>
    </DataTable>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'
import { formatCurrency, formatDate } from '@/helpers'
import GridReturPenjualanDetail from '@/components/penjualan/GridReturPenjualanDetail'
import CalendarFilter from '@/components/CalendarFilter'

export default {
  components: {
    GridReturPenjualanDetail,
    CalendarFilter,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      options: this.gridOptions,
      expandedRows: [],
      filters: {
        sr_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
        sr_between: { value: null, matchMode: FilterMatchMode.CONTAINS },
        pelanggan: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  watch: {
    'options.filters'(value) {
      if (!value.sr_between.value) {
        this.$refs.calendarFilter.clearValueOnly()
      }
    },
  },
  methods: {
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.page = 1
      this.options.filters = this.filters
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
    getStatus(id_status) {
      let status = '-'

      const list_jenis_harga = [
        { value: 1, label: 'TERMASUK PAJAK' },
        { value: 2, label: 'BELUM TERMASUK PAJAK' },
        { value: 3, label: 'NON PAJAK' },
      ]

      const res = list_jenis_harga.find((e) => e.value === id_status)
      if (res) {
        status = res.label
      }

      return status
    },
  },
}
</script>
