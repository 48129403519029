<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Pelanggan</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / Pelanggan / Data Pelanggan</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Pelanggan"
          class="mr-2"
          @click="onAddData"
        />
        <Button
          :loading="isLoadingExport"
          icon="pi pi-file-excel"
          label="Export"
          class="mr-2 p-button-outlined"
          @click="onExport()"
        />
        <Button
          icon="pi pi-download"
          class="p-button-outlined"
          label="Download form spesimen"
          @click="onDownloadFormSpesimen"
        />
      </div>
      <fx-table
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :filters="filters"
        @request="onRequestData"
      >
        <!-- :has-header="true" -->
        <!-- <template #header>
          <div style="text-align: left">
            <MultiSelect
              :modelValue="selectedColumns"
              :options="columns"
              optionLabel="header"
              @update:modelValue="onToggle"
              placeholder="Pilih kolom tambahan"
              style="width: 20em"
            />
          </div>
        </template> -->
        <template #columns>
          <Column field="kode" header="Kode" style="min-width: 12rem">
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column field="nama" header="Nama" style="min-width: 15rem" sortable>
            <template #body="{ data }">
              {{ data.nama }}
              <template v-if="data.nama_alias"
                >&nbsp;({{ data.nama_alias }})</template
              >
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder=""
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="kelompok.keterangan"
            header="Kelompok"
            style="min-width: 10rem"
          />
          <Column
            field="status_pembayaran"
            header="Pembayaran"
            style="min-width: 10rem"
          >
            <template #body="{ data }">{{
              data.status_pembayaran.toUpperCase()
            }}</template>
          </Column>
          <Column field="area.nama" header="Area" style="min-width: 10rem" />
          <Column field="alamat" header="Alamat" style="min-width: 12rem">
            <template #body="{ data }">
              {{ data.alamat }}
              {{ data.kota }}
              {{ data.kode_pos }}
            </template>
          </Column>
          <Column
            field="pengiriman_alamat"
            header="Alamat pengiriman"
            style="min-width: 290px"
          >
            <template #body="{ data }">
              {{ data.pengiriman_alamat }}
              {{ data.pengiriman_kota }}
              {{ data.pengiriman_kode_pos }}
            </template>
          </Column>
          <Column
            field="salesman"
            header="Salesman"
            style="min-width: 200px"
            sortable
          >
            <template #body="{ data }">{{ data.salesman.nama }}</template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder=""
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column field="status" header="Status" style="min-width: 230px">
            <template #body="{ data }">
              <div v-if="data.status === 'aktif'">Aktif</div>
              <div v-else>Non Aktif</div>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <Dropdown
                v-model="filterModel.value"
                :options="[
                  { value: 'aktif', text: 'Aktif' },
                  { value: 'non-aktif', text: 'Non Aktif' },
                ]"
                :showClear="true"
                optionValue="value"
                optionLabel="text"
                class="p-column-filter"
                @change="filterCallback()"
              />
            </template>
          </Column>
          <!-- <Column
            v-for="(col, index) of selectedColumns"
            :field="col.field"
            :header="col.header"
            :key="col.field + '_' + index"
            style="min-width: 180px"
          /> -->
          <Column style="flex-grow: 1; justify-content: flex-end">
            <template #body="{ data }">
              <Button
                type="button"
                icon="pi pi-download"
                class="mr-2 p-button-outlined p-button-secondary p-button-text"
                v-tooltip.top="'Download spesimen'"
                :disabled="data.spesimen.length === 0"
                @click="onDownloadSpesimen(data)"
              />
              <Button
                type="button"
                icon="pi pi-pencil"
                class="p-button-outlined p-button-secondary p-button-text mr-2"
                @click="onEditData(data)"
              />
              <Button
                type="button"
                icon="pi pi-trash"
                class="p-button-outlined p-button-danger p-button-text"
                @click="onConfirmDeletion(data)"
              />
            </template>
          </Column>
        </template>
      </fx-table>
    </div>
    <Dialog
      :header="form && form.id > 0 ? 'Edit pelanggan' : 'Tambah pelanggan'"
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '70vw' }"
      :modal="true"
      :maximizable="true"
    >
      <form-pelanggan
        :item="form"
        :loading="isLoadingSave"
        :kelompok-pelanggan="kelompokPelanggan"
        :area-pelanggan="areaPelanggan"
        :salesman="salesman"
        @save="onSaveData"
      />
    </Dialog>
    <Dialog
      header="Hapus Pelanggan"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Pelanggan <strong>{{ form.nama }}</strong> akan dihapus. Proses
          ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeletePelanggan"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import PelangganService from '@/services/PelangganService'
import KelompokPelangganService from '@/services/KelompokPelangganService'
import AreaService from '@/services/AreaService'
import SalesmanService from '@/services/SalesmanService'
import FormPelanggan from '@/components/master/FormPelanggan'
import FxTable from '@/components/FxTable'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'

export default {
  name: 'Supplier',
  components: {
    FormPelanggan,
    FxTable,
    Hotkey,
  },
  data() {
    return {
      pelangganService: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      isLoadingExport: false,
      items: [],
      form: {},
      kelompokPelanggan: [],
      areaPelanggan: [],
      salesman: [],
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'nama',
        sortOrder: -1,
        filters: null,
      },
      filters: {
        kode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        salesman: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      /* selectedColumns: null,
      columns: null, */
    }
  },
  created() {
    this.pelangganService = new PelangganService()
  },
  async mounted() {
    this.loadData()
    const kelompokPelangganService = new KelompokPelangganService()
    await kelompokPelangganService
      .get()
      .then((res) => {
        this.kelompokPelanggan = res.data.data
      })
      .catch((err) => {
        errorHandler(err, 'Data kelompok pelanggan', this)
      })
    const areaService = new AreaService()
    await areaService
      .get()
      .then((res) => {
        this.areaPelanggan = res.data.data
      })
      .catch((err) => {
        errorHandler(err, 'Data area', this)
      })
    const salesmanService = new SalesmanService()
    await salesmanService
      .get()
      .then((res) => {
        this.salesman = res.data.data
      })
      .catch((err) => {
        errorHandler(err, 'Data area', this)
      })
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.pelangganService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          /* const data = res.data.data
          const listStatusPajak = [
            { kode: 'pkp', nama: 'PKP' },
            { kode: 'non-pkp', nama: 'Non PKP' },
          ]
          const listStatusTempat = [
            { kode: 'sewa', nama: 'Sewa' },
            { kode: 'hak-milik', nama: 'Hak Milik' },
          ]
          data.forEach((item) => {
            if (item.status_pajak.length > 0) {
              item.status_pajak = item.status_pajak.toLowerCase()
              if (item.status_pajak && item.status_pajak !== '-') {
                item['status_pajak_text'] = listStatusPajak.find(
                  (x) => x.kode === item.status_pajak
                ).nama
              }
            } else {
              item['status_pajak_text'] = ''
            }
            if (item.status_tempat.length > 0) {
              item.status_tempat = item.status_tempat.toLowerCase()
              item['status_tempat_text'] = listStatusTempat.find(
                (x) => x.kode === item.status_tempat
              ).nama
            } else {
              item['status_tempat_text'] = ''
            }
          })
          this.items = data */
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data pelanggan', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onEditData(data) {
      data.sipa_validity = data.sipa_validity
        ? new Date(data.sipa_validity)
        : null

      data.sia_validity = data.sia_validity ? new Date(data.sia_validity) : null

      data.sewa_validity = data.sewa_validity
        ? new Date(data.sewa_validity)
        : null

      data.cdob_validity = data.cdob_validity
        ? new Date(data.cdob_validity)
        : null

      this.form = Object.assign({}, data)

      if (data.salesman != null) {
        this.form.salesman_id = data.salesman.id
      }
      if (data.area != null) {
        this.form.area_id = data.area.id
      }
      if (data.kelompok != null) {
        this.form.kelompok_id = data.kelompok.id
      }

      this.dialog = true
    },
    onAddData() {
      this.form.id = 0
      this.form.kelompok_id = null
      this.form.area_id = null
      this.form.salesman_id = null
      this.form.kode = ''
      this.form.kode_harga = ''
      this.form.nama = ''
      this.form.nama_alias = ''
      this.form.nama_pajak = ''
      this.form.nama_apoteker = ''
      this.form.alamat = ''
      this.form.kota = ''
      this.form.kode_pos = ''
      this.form.gmap = ''
      this.form.telepon_1 = ''
      this.form.telepon_2 = ''
      this.form.pengiriman_alamat = ''
      this.form.pengiriman_kota = ''
      this.form.pengiriman_kode_pos = ''
      this.form.pengiriman_gmap = ''
      this.form.pengiriman_penerima = ''
      this.form.fax = ''
      this.form.npwp = ''
      this.form.status_pajak = 'pkp'
      this.form.status_pembayaran = 'tunai'
      this.form.no_izin = ''
      this.form.no_sia = ''
      this.form.nitku = ''
      this.form.no_sipa = ''
      this.form.sia_validity = ''
      this.form.sipa_validity = ''
      this.form.cdob_validity = ''
      this.form.email = ''
      this.form.nppkp = ''
      this.form.top = 0
      this.form.batas_kredit = 0
      this.form.status_tempat = ''
      this.form.sewa_validity = ''
      this.form.ktp_pemilik = ''
      this.form.ktp_apoteker = ''
      this.form.foto_bangunan = ''
      this.form.kode_ereport = ''
      this.form.status = 'aktif'
      this.dialog = true
    },
    onSaveData(data, image1, image2, image3, file) {
      this.isLoadingSave = true
      if (data && data.id === 0) {
        const newData = Object.assign({}, data)

        delete newData.ktp_pemilik
        delete newData.ktp_apoteker
        delete newData.foto_bangunan
        delete newData.spesimen

        this.pelangganService
          .add(newData)
          .then((res) => {
            if (res.data.status === 200) {
              this.uploadImage(res.data.data, 'ktp_pemilik', image1)
              this.uploadImage(res.data.data, 'ktp_apoteker', image2)
              this.uploadImage(res.data.data, 'foto_bangunan', image3)
              if (file != null) {
                this.uploadFile(newData.id, file)
              } else {
                this.loadData()
              }
              this.$toast.add({
                severity: 'success',
                summary: 'Data pelanggan',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data pelanggan', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else if (data && data.id >= 0) {
        const newData = Object.assign({}, data)

        delete newData.ktp_pemilik
        delete newData.ktp_apoteker
        delete newData.foto_bangunan
        delete newData.spesimen

        this.pelangganService
          .update(newData)
          .then((res) => {
            if (res.data.status === 200) {
              this.uploadImage(newData.id, 'ktp_pemilik', image1)
              this.uploadImage(newData.id, 'ktp_apoteker', image2)
              this.uploadImage(newData.id, 'foto_bangunan', image3)
              if (file != null) {
                this.uploadFile(newData.id, file)
              } else {
                this.loadData()
              }

              this.$toast.add({
                severity: 'success',
                summary: 'Data pelanggan',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data pelanggan', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeletePelanggan() {
      this.pelangganService
        .delete(this.form.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Data Pelanggan',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data Supplier', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    uploadImage(id, field, value) {
      if (value) {
        this.pelangganService
          .upload(id, field, value)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Data pelanggan',
                detail: 'Foto/KTP berhasil diupload.',
                life: 3000,
              })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Upload foto/KTP pelanggan', this)
          })
      }
    },
    uploadFile(id, value) {
      if (value) {
        this.pelangganService
          .upload(id, 'spesimen', value)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Data supplier',
                detail: 'Spesimen berhasil diupload.',
                life: 3000,
              })
              this.loadData()
            }
          })
          .catch((err) => {
            errorHandler(err, 'Upload spesimen', this)
          })
      }
    },
    onDownloadFormSpesimen() {
      this.pelangganService
        .downloadFormSpesimen()
        .then((res) => {
          if (res.status !== 200) {
            this.$toast.add({
              severity: 'error',
              summary: 'Data Pelanggan',
              detail: 'Gagal mengunduh spesimen.',
              life: 3000,
            })
          } else {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'spesimen-pelanggan.pdf')
            document.body.appendChild(link)
            link.click()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Download spesimen', this)
        })
    },
    onDownloadSpesimen(data) {
      const link = document.createElement('a')
      link.href = data.spesimen
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
    },
    onExport() {
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters
      this.isLoadingExport = true
      this.pelangganService
        .export(sortField, sortOrder, filters)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'data-pelanggan.xls')
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          errorHandler(err, 'Export Data Pelanggan', this)
        })
        .finally(() => (this.isLoadingExport = false))
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddData()
      }
    },
    /* onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col))
    }, */
  },
}
</script>
